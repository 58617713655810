import Api from './api';


export const USER_ROLES = 'user_roles';
export const INTEREST_MASTER = 'interest_master';
export const EVENT_MASTER = 'event_master';
export const SERVICE_MASTER = 'service_master';
export const BUY_FORM = 'buy_form';
export const LOADING_DATA = 'loading_data';
export const GET_DATA_BY_TYPE ='get_data_by_type';

export const FETCH_DESTINATION_DATA = 'fetch_destination_data';
export const FETCH_DESTINATION_DETAILS = 'fetch_destination_details';

export const FETCH_SMART_SOLUTION_DATA = 'fetch_smart_solution_data';
export const FETCH_SMART_SOLUTION_DETAILS = 'fetch_smart_solution_details';

export const FETCH_BIKE_EXPERT_DATA = 'fetch_bike_expert_data';

export const FETCH_EVENTS_DATA = 'fetch_events_data';
export const FETCH_BUY_DATA = 'fetch_buy_data';

export const FETCH_BUY_DETAILS = 'fetch_buy_details';
export const FETCH_EVENT_DETAILS = 'fetch_event_details';
export const FETCH_BIKE_EXPERT_DETAILS = 'fetch_expert_details';
export const ADD_MY_COUNTRY = 'add_my_country';

export const ADD_FAVOURITE = 'add_favourite';

export const RESET_REVIEW_LISTING = 'reset_review_listing';
export const RESET_SEARCH = 'reset_search';

export const FETCH_USER_BUY_LIST = 'fetch_user_buy_list';

export const FETCH_SERVICES_DATA = 'fetch_services_data';
export const FETCH_SERVICES_DETAILS = 'fetch_services_details';

export const GET_MY_STATISTIC = 'get_my_statistic';

//export const 

export const getUserRole = () => {

    return async (dispatch) => {
        try {
            const api = new Api();
            const result = await api.submitWithOutLogin(`roles`, 'GET');
            if (result.status === 'ok') {
                return dispatch({
                    type: USER_ROLES,
                    data: result.data,
                    error: false
                });
            } else {
                return dispatch({
                    type: USER_ROLES,
                    error: true,
                    data: null,
                    msg: result.msg
                });
            }

        } catch (error) {
            return dispatch({
                type: USER_ROLES,
                error: true,
                msg: error
            });
        }
    }
}

export const getEventMaster = () => {

    return async (dispatch) => {
        try {
            const api = new Api();
            const result = await api.submitWithOutLogin(`event/data`, 'GET');
            if (result.status === 'ok') {
                return dispatch({
                    type: EVENT_MASTER,
                    data: result.data,
                    error: false
                });
            } else {
                return dispatch({
                    type: EVENT_MASTER,
                    error: true,
                    data: null,
                    msg: result.msg
                });
            }

        } catch (error) {
            return dispatch({
                type: EVENT_MASTER,
                error: true,
                msg: error
            });
        }
    }
}

export const getInterestMaster = () => {
    return async (dispatch) => {
        try {
            const api = new Api();
            const result = await api.submitWithOutLogin(`interest`, 'GET');
            if (result.status === 'ok') {
                return dispatch({
                    type: INTEREST_MASTER,
                    data: result.data,
                    error: false
                });
            } else {
                return dispatch({
                    type: INTEREST_MASTER,
                    error: true,
                    data: null,
                    msg: result.msg
                });
            }

        } catch (error) {
            return dispatch({
                type: INTEREST_MASTER,
                error: true,
                msg: error
            });
        }
    }
}

export const getServiceMaster = () => {

    return async (dispatch) => {
        try {
            const api = new Api();
            const result = await api.submitWithOutLogin(`service/type`, 'GET');
            if (result.status === 'ok') {
                return dispatch({
                    type: SERVICE_MASTER,
                    data: result.data,
                    error: false
                });
            } else {
                return dispatch({
                    type: SERVICE_MASTER,
                    error: true,
                    data: null,
                    msg: result.msg
                });
            }

        } catch (error) {
            return dispatch({
                type: SERVICE_MASTER,
                error: true,
                msg: error
            });
        }
    }
}

export const submitForm = async(url,formValue, method = 'POST')=>{
    try{
        const api = new Api();
        const result = await api.submitWithParams(`${url}`, method, formValue);
        console.log(result)
        if(result.status === 'ok'){ 
            return {
                error: false,
                data: result.data,
                content_id: result.content_id,
            }
         }else{
            return {
                error: true,
                data: [],
                msg: result.msg
            } 
         }
    }catch(err){

    }
}

export const submitFormWithFile = async(url,formValue)=>{
    try{
        const api = new Api();
        const result = await api.submitWithParamsAndFile(`${url}`, 'POST', formValue);
        if(result.status === 200){ 
            return {
                error: false,
                data: result.response
            }
         }else{
            return {
                error: true,
                data: []
            } 
         }
    }catch(err){

    }
}

export const getSeoData = async(formValue)=>{
    try{
        const api = new Api();
        const result = await api.submitWithParamsWithOutLOgin(`get_seo_data`, 'POST', formValue);
        if(result.status === 'ok'){ 
            return {
                error: false,
                data: result.data
            }
         }else{
            return {
                error: true,
                data: []
            } 
         }
    }catch(err){

    }
}



export const submitBuyForm = (formValue) => {
    return async (dispatch) => {
        try {
            const api = new Api();
            const result = await api.submitWithParams(`interest`, 'POST', formValue);
            if (result.status === 'ok') {
                return dispatch({
                    type: BUY_FORM,
                    data: result.data,
                    error: false
                });
            } else {
                return dispatch({
                    type: BUY_FORM,
                    error: true,
                    data: null,
                    msg: result.msg
                });
            }

        } catch (error) {
            return dispatch({
                type: BUY_FORM,
                error: true,
                msg: error
            });
        }
    }
}

export const callLoading =(status)=>{
    return async (dispatch)=>{
        return dispatch({
            type:LOADING_DATA,
            status:status
        })
    }
}

export const getDataByType = (page_no, data_type, url,search_key='',) => {
    return async (dispatch) => {
        try {
            const api = new Api();
            const result = await api.submit(url + '?page=' + page_no + '&search_key=' + search_key, 'GET');
            if (result.status === 'ok') {
                return dispatch({
                    type: GET_DATA_BY_TYPE,
                    data: result.data,
                    data_type:data_type,
                    error: false
                });
            } else {
                return dispatch({
                    type: GET_DATA_BY_TYPE,
                    error: true,
                    data: null,
                    data_type:data_type,
                    msg: result.msg
                });
            }

        } catch (error) {
            return dispatch({
                type: GET_DATA_BY_TYPE,
                error: true,
                data_type:data_type,
                msg: error
            });
        }
    }
}

export const getLatLong = async(value)=>{
    try{
        let url = `https://maps.googleapis.com/maps/api/geocode/json?sensor=false&language=en&address=${value}&key=AIzaSyBesQCdz9tMKn6rJG4YKLbYGx1cW0pfJKQ`
        let response = await fetch(url);
        if (response.ok) { 
            let json = await response.json();
            return json
          } else {
              return response.status
          }
    }catch(err){

    }
}

export const getDestinationList = ( pageNo = 0, queryData = {} ) =>{
    return async dispatch =>{
        try{
            let _query;
            if( Object.keys(queryData).length > 0 ){
                _query = `&${_getQueryParamsFromObject( queryData )}`;
                //console.log("Query ======= ", query );
            }
            
            const api = new Api();
            const result = await api.submit(`api/bike-destination?page=${pageNo}${ _query ? _query : '' }`);
            
            if( result.status === 'ok' ){
                return dispatch({
                    type: FETCH_DESTINATION_DATA,
                    data: { data: result.data, pageData: result.pageData, seoData:result.seoData },
                    error: false
                });
            }
            else{

                return dispatch({
                    type: FETCH_DESTINATION_DATA,
                    data: {},
                    error: true
                });
            }

        }catch( error ){
            console.log( "ERROR ======= ", error);
            return dispatch({
                type: FETCH_DESTINATION_DATA,
                data: {},
                error: true
            });
        }
    }
}

export const getDestinationDetails = ( pageNo = 0, queryData = {} ) =>{
    return async dispatch =>{
        try{
            let _query;
            if( Object.keys(queryData).length > 0 ){
                _query = `&${_getQueryParamsFromObject( queryData )}`;
                //console.log("Query ======= ", query );
            }
            
            const api = new Api();
            const result = await api.submit(`api/bike-destination-detail?page=${pageNo}${ _query ? _query : '' }`);
            
            if( result.status === 'ok' ){
                return dispatch({
                    type: FETCH_DESTINATION_DETAILS,
                    data: { data: result.data, pageData: result.pageData, seoData:result.seoData },
                    error: false
                });
            }
            else{

                return dispatch({
                    type: FETCH_DESTINATION_DETAILS,
                    data: {},
                    error: true
                });
            }

        }catch( error ){
            console.log( "ERROR ======= ", error);
            return dispatch({
                type: FETCH_DESTINATION_DETAILS,
                data: {},
                error: true
            });
        }
    }
}

export const getSmartSolutionList = ( pageNo = 0, queryData = {} ) =>{
    return async dispatch =>{
        try{
            let _query;
            if( Object.keys(queryData).length > 0 )
                _query = `&${_getQueryParamsFromObject( queryData )}`;
            
            const api = new Api();
            const result = await api.submit(`smart-solutions?page=${pageNo}${ _query ? _query : '' }`);
            
            if( result.status === 'ok' ){
                return dispatch({
                    type: FETCH_SMART_SOLUTION_DATA,
                    data: { data: result.data, pageData: result.pageData,seoData:result.seoData },
                    error: false
                });
            }
            else{

                return dispatch({
                    type: FETCH_SMART_SOLUTION_DATA,
                    data: {},
                    error: true
                });
            }

        }catch( error ){
            console.log( "ERROR ======= ", error);
            return dispatch({
                type: FETCH_SMART_SOLUTION_DATA,
                data: {},
                error: true
            });
        }
    }
}

export const getSmartSolutionDetails = ( pageNo = 0, queryData = {} ) =>{
    return async dispatch =>{
        try{
            let _query;
            if( Object.keys(queryData).length > 0 ){
                _query = `&${_getQueryParamsFromObject( queryData )}`;
                //console.log("Query ======= ", query );
            }
            
            const api = new Api();
            const result = await api.submit(`smart-solutions/detail?page=${pageNo}${ _query ? _query : '' }`);
            
            if( result.status === 'ok' ){
                return dispatch({
                    type: FETCH_SMART_SOLUTION_DETAILS,
                    data: { data: result.data, pageData: result.pageData, seoData:result.seoData },
                    error: false
                });
            }
            else{

                return dispatch({
                    type: FETCH_SMART_SOLUTION_DETAILS,
                    data: {},
                    error: true
                });
            }

        }catch( error ){
            console.log( "ERROR ======= ", error);
            return dispatch({
                type: FETCH_SMART_SOLUTION_DETAILS,
                data: {},
                error: true
            });
        }
    }
}

export const getBikeExpertList = ( pageNo = 0, queryData = {} ) =>{
    return async dispatch =>{
        try{
            
            let _query;
            
            if( Object.keys(queryData).length > 0 )
                _query = `&${_getQueryParamsFromObject( queryData )}`;
            
            const api = new Api(); 
            const result = await api.submit(`bike-expert?page=${pageNo}${ _query ? _query : '' }`);
            
            if( result.status === 'ok' ){
                return dispatch({
                    type: FETCH_BIKE_EXPERT_DATA,
                    data: { data: result.data, pageData: result.pageData, seoData:result.seoData },
                    error: false
                });
            }
            else{

                return dispatch({
                    type: FETCH_BIKE_EXPERT_DATA,
                    data: {},
                    error: true
                });
            }

        }catch( error ){
            console.log( "ERROR ======= ", error);
            return dispatch({
                type: FETCH_BIKE_EXPERT_DATA,
                data: {},
                error: true
            });
        }
    }
}

export const getEventList = ( pageNo = 0, queryData = {} ) =>{
    return async dispatch =>{
        try{
            let _query;
            if( Object.keys(queryData).length > 0 ){
                _query = `&${_getQueryParamsFromObject( queryData )}`;
                //console.log("Query ======= ", query );
            }
            
            const api = new Api();
            const result = await api.submit(`events?page=${pageNo}${ _query ? _query : '' }`);
            
            if( result.status === 'ok' ){
                return dispatch({
                    type: FETCH_EVENTS_DATA,
                    data: { data: result.data, pageData: result.pageData, facets:result.facets, seoData:result.seoData},
                    error: false
                });
            }
            else{

                return dispatch({
                    type: FETCH_EVENTS_DATA,
                    data: {},
                    error: true
                });
            }

        }catch( error ){
            console.log( "ERROR ======= ", error);
            return dispatch({
                type: FETCH_EVENTS_DATA,
                data: {},
                error: true
            });
        }
    }
}

export const getEventSubList = (url) =>{
    return async dispatch =>{
        try{
            const api = new Api();
            const result = await api.submitWithDirectUrl(url);
            
            if( result.status === 'ok' ){
                return dispatch({
                    type: FETCH_EVENTS_DATA,
                    data: { data: result.data, pageData: result.pageData, facets:result.facets},
                    error: false
                });
            }
            else{

                return dispatch({
                    type: FETCH_EVENTS_DATA,
                    data: {},
                    error: true
                });
            }

        }catch( error ){
            console.log( "ERROR ======= ", error);
            return dispatch({
                type: FETCH_EVENTS_DATA,
                data: {},
                error: true
            });
        }
    }
}

export const getBuyList = ( pageNo = 0, queryData = {} ) =>{
    return async dispatch =>{
        try{
            let _query;
            if( Object.keys(queryData).length > 0 ){
                _query = `&${_getQueryParamsFromObject( queryData )}`;
                //console.log("Query ======= ", query );
            }
            
            const api = new Api();
            const result = await api.submit(`buy?page=${pageNo}${ _query ? _query : '' }`);
            
            if( result.status === 'ok' ){
                return dispatch({
                    type: FETCH_BUY_DATA,
                    data: { data: result.data, pageData: result.pageData, facets:result.facets, seoData:result.seoData},
                    error: false
                });
            }
            else{

                return dispatch({
                    type: FETCH_BUY_DATA,
                    data: {},
                    error: true
                });
            }

        }catch( error ){
            console.log( "ERROR ======= ", error);
            return dispatch({
                type: FETCH_BUY_DATA,
                data: {},
                error: true
            });
        }
    }
}

export const getUserFormDataList = async ( url, pageNo = 0, queryData = {} ) =>{
    
        try{
            let _query;
            if( Object.keys(queryData).length > 0 ){
                _query = `&${_getQueryParamsFromObject( queryData )}`;
                //console.log("Query ======= ", query );
            }
            
            const api = new Api();
            const result = await api.submit(`${url}/userdata?page=${pageNo}${ _query ? _query : '' }`);
            
            if( result.status === 'ok' ){
                return {
                    data: { data: result.data, pageData: result.pageData, facets:result.facets},
                    error: false
                };
            }
            else{

                return {
                    data: {},
                    error: true
                };
            }

        }catch( error ){
            console.log( "ERROR ======= ", error);
            return {
                data: {},
                error: true
            };
        }
    
}

export const getBuySubList = (url) =>{
    return async dispatch =>{
        try{
            const api = new Api();
            const result = await api.submitWithDirectUrl(url);
            
            if( result.status === 'ok' ){
                return dispatch({
                    type: FETCH_BUY_DATA,
                    data: { data: result.data, pageData: result.pageData, facets:result.facets},
                    error: false
                });
            }
            else{

                return dispatch({
                    type: FETCH_BUY_DATA,
                    data: {},
                    error: true
                });
            }

        }catch( error ){
            console.log( "ERROR ======= ", error);
            return dispatch({
                type: FETCH_BUY_DATA,
                data: {},
                error: true
            });
        }
    }
}

export const submitReview = async( data ) =>{

    try{
                   
        const api = new Api(); 
        const result = await api.submitWithParams('feedback', 'POST', data);
        
        if( result.status === 'ok' ){
            return {
                data: result.data,
                error: false
            };
        }
        else{

            return {
                data: {},
                error: true
            };
        
        }

    }catch( error ){
        console.log( "ERROR ======= ", error);
        return {
            data: {},
            error: true
        };
    }

}

export const getReviews = async( url, content_id, pageNo = 0 ) =>{

try{
            
    const api = new Api();
    const result = await api.submitWithOutLogin(`${url}?page=${pageNo}&content_id=${content_id}`);
    
    if( result.status === 'ok' ){
        return {
            
            data: { data: result.data, pageData: result.pageData },
            error: false
        };
    }
    else{

        return {
            
            data: {},
            error: true
        };
    }

}catch( error ){
    
    console.log( "ERROR ======= ", error);
    return {
        data: {},
        error: true
    };

}

}

const _getQueryParamsFromObject = ( data ) =>{
    
    const params = Object.keys(data).map( _k => `${_k}=${data[_k]}` ).join('&');
    return params;
}

export const getBuyDetails = ( pageNo = 0, queryData = {} ) =>{
    return async dispatch =>{
        try{
            let _query;
            if( Object.keys(queryData).length > 0 ){
                _query = `&${_getQueryParamsFromObject( queryData )}`;
                //console.log("Query ======= ", query );
            }
            
            const api = new Api();
            const result = await api.submit(`buy/detail?page=${pageNo}${ _query ? _query : '' }`);
            
            if( result.status === 'ok' ){
                return dispatch({
                    type: FETCH_BUY_DETAILS,
                    data: { data: result.data, pageData: result.pageData, seoData:result.seoData },
                    error: false
                });
            }
            else{

                return dispatch({
                    type: FETCH_BUY_DETAILS,
                    data: {},
                    error: true
                });
            }

        }catch( error ){
            console.log( "ERROR ======= ", error);
            return dispatch({
                type: FETCH_BUY_DETAILS,
                data: {},
                error: true
            });
        }
    }
}

export const getEventDetails = ( pageNo = 0, queryData = {} ) =>{
    return async dispatch =>{
        try{
            let _query;
            if( Object.keys(queryData).length > 0 ){
                _query = `&${_getQueryParamsFromObject( queryData )}`;
                //console.log("Query ======= ", query );
            }
            
            const api = new Api();
            const result = await api.submit(`event/detail?page=${pageNo}${ _query ? _query : '' }`);
            
            if( result.status === 'ok' ){
                return dispatch({
                    type: FETCH_EVENT_DETAILS,
                    data: { data: result.data, pageData: result.pageData, seoData:result.seoData },
                    error: false
                });
            }
            else{

                return dispatch({
                    type: FETCH_EVENT_DETAILS,
                    data: {},
                    error: true
                });
            }

        }catch( error ){
            console.log( "ERROR ======= ", error);
            return dispatch({
                type: FETCH_EVENT_DETAILS,
                data: {},
                error: true
            });
        }
    }
}

export const getBikeExpertDetails = ( pageNo = 0, queryData = {} ) =>{
    return async dispatch =>{
        try{
            let _query;
            if( Object.keys(queryData).length > 0 ){
                _query = `&${_getQueryParamsFromObject( queryData )}`;
                //console.log("Query ======= ", query );
            }
            
            const api = new Api();
            const result = await api.submit(`bike-expert/detail?page=${pageNo}${ _query ? _query : '' }`);
            
            if( result.status === 'ok' ){
                return dispatch({
                    type: FETCH_BIKE_EXPERT_DETAILS,
                    data: { data: result.data, pageData: result.pageData, seoData:result.seoData },
                    error: false
                });
            }
            else{

                return dispatch({
                    type: FETCH_BIKE_EXPERT_DETAILS,
                    data: {},
                    error: true
                });
            }

        }catch( error ){
            console.log( "ERROR ======= ", error);
            return dispatch({
                type: FETCH_BIKE_EXPERT_DETAILS,
                data: {},
                error: true
            });
        }
    }
}



export const getAutoSearchData = async(url,formValue)=>{
    try{
    
        const api = new Api();
        const result = await api.submitWithParams(`${url}`, 'POST', formValue);
        if(result.status === 'ok'){ 
            return {
                error: false,
                data: result.data
            }
         }else{
            return {
                error: true,
                data: []
            } 
         }
    }catch(err){

    }
}

export const getMyCountry = () =>{
    return async dispatch =>{
        try{
            const _url = 'https://admin.carryon.bike/carryon/ip/details';
            const response = await fetch( _url, {
                method: 'GET'
            });
            const result = await response.json();
            
            if( result && result.type === 'success' ){
                const { data } = result;
                
                return dispatch({
                    type: ADD_MY_COUNTRY,
                    data: { countryName: data && data.country_name ? data.country_name : null, 
                            countryCode: data && data.country_code ? data.country_code : null },
                    error: false 
                });
            }

            return dispatch({
                type: ADD_MY_COUNTRY,
                data: { countryName: null, 
                        countryCode: null },
                error: true
            });

        }catch( error ){
            console.log("MY COUNTRY ERROR ====== ", error );
            return dispatch({
                type: ADD_MY_COUNTRY,
                data: { countryName: null, 
                    countryCode: null },
                error: true
            })
        }
    }
}

export const addingFavourite = (content_id, type) => {
    return async (dispatch) => {
        try {
            const api = new Api();
            const result = await api.submitWithParams(`like`, 'POST', {content_id:content_id});
            if (result.status === 'ok') {
                return dispatch({
                    type: ADD_FAVOURITE,
                    data: {content_id:content_id, type:type},
                    error: false
                });
            } else {
                return dispatch({
                    type: ADD_FAVOURITE,
                    error: true,
                    data: null,
                    msg: result.msg
                });
            }

        } catch (error) {
            return dispatch({
                type: ADD_FAVOURITE,
                error: true,
                msg: error
            });
        }
    }
}

export const resetReviewListing = ( status ) =>{
    return dispatch => {
        dispatch({ type: RESET_REVIEW_LISTING, value: status })
    };
}

export const clearSearch = ( status ) => {
    return dispatch =>{
        dispatch({type: RESET_SEARCH, value: status })
    };
}

export const getFormEditContent = async( extendedUrl, queryData ) => {
    try{
        let _query;
        if( Object.keys(queryData).length > 0 ){
            _query = `&${_getQueryParamsFromObject( queryData )}`;
            //console.log("Query ======= ", query );
        }
        
        const api = new Api();
        const result = await api.submit(`${extendedUrl}/userdata?${ _query }`);
        
        if( result.status === 'ok' ){
            return {
                data: { data: result.data, pageData: result.pageData, facets:result.facets},
                error: false
            };
        }
        else{

            return {
                data: {},
                error: true
            };
        }

    }catch( error ){
        console.log( "ERROR ======= ", error);
        return {
            data: {},
            error: true
        };
    }
}

export const getFormContent = (url,type) =>{

    return async (dispatch) => {
        try {
            const api = new Api();
            const result = await api.submit(url);
            if (result.status === 'ok') {
                return dispatch({
                    type: type,
                    data: { data: result.data, pageData: result.pageData },
                    error: false
                });
            } else {
                return dispatch({
                    type: type,
                    error: true,
                    data: null,
                    msg: result.msg
                });
            }

        } catch (error) {
            return dispatch({
                type: type,
                error: true,
                msg: error
            });
        }
    }

}

export const getServicesList = ( pageNo = 0, queryData = {} ) =>{
    return async dispatch =>{
        try{
            let _query;
            if( Object.keys(queryData).length > 0 ){
                _query = `&${_getQueryParamsFromObject( queryData )}`;
                //console.log("Query ======= ", query );
            }
            
            const api = new Api();
            const result = await api.submit(`service?page=${pageNo}${ _query ? _query : '' }`);
            
            if( result.status === 'ok' ){
                return dispatch({
                    type: FETCH_SERVICES_DATA,
                    data: { data: result.data, pageData: result.pageData, seoData:result.seoData },
                    error: false
                });
            }
            else{
                return dispatch({
                    type: FETCH_SERVICES_DATA,
                    data: {},
                    error: true
                });
            }

        }catch( error ){
            console.log( "ERROR ======= ", error);
            return dispatch({
                type: FETCH_SERVICES_DATA,
                data: {},
                error: true
            });
        }
    }
}

export const getServicesDetails = ( pageNo = 0, queryData = {} ) =>{
    return async dispatch =>{
        try{
            let _query;
            if( Object.keys(queryData).length > 0 ){
                _query = `&${_getQueryParamsFromObject( queryData )}`;
                //console.log("Query ======= ", query );
            }
            
            const api = new Api();
            const result = await api.submit(`service/detail?page=${pageNo}${ _query ? _query : '' }`);
            
            if( result.status === 'ok' ){
                return dispatch({
                    type: FETCH_SERVICES_DETAILS,
                    data: { data: result.data, pageData: result.pageData, seoData:result.seoData },
                    error: false
                });
            }
            else{

                return dispatch({
                    type: FETCH_SERVICES_DETAILS,
                    data: {},
                    error: true
                });
            }

        }catch( error ){
            console.log( "ERROR ======= ", error);
            return dispatch({
                type: FETCH_SERVICES_DETAILS,
                data: {},
                error: true
            });
        }
    }
}

export const uploadedImages = async( formType, formData ) => {
    try{
        
        const api = new Api();
        const result = await api.submitWithParamsAndFile(`file/uploads/node/${formType}`,'POST',formData);
        
        if( result.status === 'ok' ){
            return {
                data: { data: result.data},
                error: false
            };
        }
        else{

            return {
                data: {},
                error: true
            };
        }

    }catch( error ){
        console.log( "ERROR ======= ", error);
        return {
            data: {},
            error: true
        };
    }
}

export const getMyStatistic =(url)=>{
    return async dispatch =>{
    try{        
        const api = new Api();
        const result = await api.submit(url);
        
        if( result.status === 'ok' ){
            return dispatch({
                type: GET_MY_STATISTIC,
                data: { data: result.data},
                error: false
            })
        }
        else{

            return dispatch({
                type: GET_MY_STATISTIC,
                data: {},
                error: true
            })
        }

    }catch( error ){
        console.log( "ERROR ======= ", error);
        return dispatch({
            type: GET_MY_STATISTIC,
            data: {},
            error: true
        })
        }
    }
}


