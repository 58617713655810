import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/bootstrap.min.css';
import './assets/css/font-awesome.min.css';
import './assets/css/style.css';
import './assets/css/style1.css';
import ErrorBoudary from './ErrorBoudary';

const MainRoutes = lazy(() => import('./routes/route') );
const AppProvider = lazy(() => import('./stores/reducers') ); 

function App() {
  return (
    <Suspense fallback={<div/>}>
      <AppProvider>
        <Router>
          <ErrorBoudary>
            <MainRoutes/>
          </ErrorBoudary>
          
          <ToastContainer autoClose={3000}/>
        </Router>
      </AppProvider>
    </Suspense>
  );
}

export default App;
