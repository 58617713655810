import React,{useState} from 'react';
import { COOKIE_COUNTRY } from '../constants/config';

function BackToEnglishButton({languageOpenStatus}) {
    const [isLanguageOpen, setIsLanguageOpen] = useState(false);
    const _handleBaseLanguage = () => {
        //ev.preventDefault();

        const language = 'en';
        const tag = 'United States';
        const mainTag = '#googtrans(en|en)';
        var d = new Date();
        d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        //document.cookie = `${COOKIE_NAME}=${TRANSLATE_BASE_PATH}${language};${expires};path=/`;
        document.cookie = `${COOKIE_COUNTRY}=${tag};${expires};path=/`;
        //window.location.href = location.pathname + mainTag;
        //window.location.reload();

        var els = document.querySelectorAll('.goog-te-combo');
        if (els.length > 0) {
            els.forEach(el => {
                el.value = language;
                el.dispatchEvent(new Event('change'));
            });

            localStorage.setItem('isDefaultSelected', '1');
            setIsLanguageOpen(false);
            if(languageOpenStatus && typeof languageOpenStatus === 'function')
                languageOpenStatus(false);
        }

    }

    return (
        <span 
            className="notranslate baseLang" 
            onClick={_handleBaseLanguage}
        >Back to English</span>
    )
}

export default BackToEnglishButton;
