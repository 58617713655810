import React, { useState} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { countryLanguageList } from '../constants/country-language';
import { COOKIE_NAME, COOKIE_COUNTRY, TRANSLATE_BASE_PATH } from '../constants/config';

const LanguageTranslate = (props) => {
    const { onClose } = props;
    const location = useLocation();
    const myCountry = useSelector( state => state.common.myCountry );
    const _europianCountryList = countryLanguageList[1].content;
    const [ selectedLanguage, setSelectedLanguage ] = React.useState( null );
    const [ tag, setTag ] = React.useState( null );
    
    const _getCookieData = ( cname ) =>{

        var name = cname + "=";
        var decodedCookie = decodeURIComponent( document.cookie );
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
            c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
            }
        }
        return "";

    }

    const _loadLanguage = ( tag, mainTag, language = null ) =>{
        
        var d = new Date();
        
        //d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
        //var expires = "expires="+d.toUTCString();
        //document.cookie = `${COOKIE_NAME}=${TRANSLATE_BASE_PATH}${language};${expires};path=/`;
        //document.cookie = `${COOKIE_COUNTRY}=${tag};${expires};path=/`;

        var els = document.querySelectorAll('.goog-te-combo');
        //console.log( els.length );
        if( els.length > 0 ){
            els.forEach(el => {
                el.value = language;  
                el.dispatchEvent(new Event('change'));
            })

            setSelectedLanguage( language );
            localStorage.setItem('isDefaultSelected', '0');
        }
        // el.value = language;
        // el.dispatchEvent(new Event('change'));
        onClose();
        //setSelectedLanguage( language );
        //localStorage.setItem('isDefaultSelected', '0');
    
    }
    
    const _changeLanguageHandler = ( ev, language, tag, mainTag ) =>{
        
        ev.preventDefault();
        _loadLanguage(tag, mainTag, language);
    }

    React.useEffect(()=>{
        //const myCountry = 'Norway';
        const europCountry = _europianCountryList.find( c => c.country === myCountry );
        //const _lanCountry = _getCookieData( COOKIE_COUNTRY );
        const isDefaultSelected = localStorage.getItem('isDefaultSelected') || '0'; 
        if( europCountry && myCountry && europCountry.country && europCountry.country === myCountry && isDefaultSelected !== '1' )
        {
            window.setTimeout(()=>{
                var els = document.querySelectorAll('.goog-te-combo');
                
                if( els.length > 0 ){
                    
                    els.forEach(el => {
                        el.value = europCountry.language;
                        el.dispatchEvent(new Event('change'));
                        setSelectedLanguage( europCountry.language );
                    })
        
                }
                
            }, 600)
            ;
            return;
        }

        const langData = _getCookieData( COOKIE_NAME );
        const _lang = langData.split(TRANSLATE_BASE_PATH)[1];
        //setTag( _getCookieData( COOKIE_COUNTRY ) );
        setSelectedLanguage( 
            _lang ? _lang : null
        )
    },[myCountry]);

    //console.log( location );
    
    return (
        <div className="listdropdownholder">
            {
                countryLanguageList.length > 0 && countryLanguageList.map((item, index) =>
                <div className="continentdiv" key={index}>
                    <h3>{ item.region }</h3>
                    <ul>
                        {
                            item.content && item.content.length > 0 && item.content.map((country, ind) => 
                                <li 
                                    key={ind} 
                                    className={ selectedLanguage && tag && selectedLanguage === country.language && tag === country.country ? 'selected' : '' }>
                                    <Link 
                                        to="#" 
                                        onClick={(ev) => _changeLanguageHandler(ev, country.language, country.country, country.tag ) } 
                                        className="lang-es" data-lang={ country.language }
                                    >{ country.label }</Link>
                                </li>
                            )
                        }
                    </ul>
                </div>
                )
            }
            
        </div>
    )
}

export default LanguageTranslate;
