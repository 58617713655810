import React from 'react'
import Footer from '../includes/Footer'
import Header from '../includes/Header'

const ErrorHandle =(props)=>{
    return (
        <>
          <Header />
          <div className="banner innerbanner ">
                <img  src={require('../assets/images/404.jpg')} alt="blog"/> 	       	 
            </div> 
            <main className="site-main inner-main "> 
			
			<div className="partner-section">
				<div className="container">
					<div className="tophead">
						<h2 className="title1">
							oops! something is wrong
						</h2>  
					</div>
				</div>
			</div>

		</main>  

        <Footer />
        </>

    )
}

export default ErrorHandle
