import React, { Component } from 'react'
import ErrorHandle from './pages/ErrorHandle';

export class ErrorBoudary extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             hasError:false
        }
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
      }
    
    
    render() {
        if(this.state.hasError){
            return (
                <ErrorHandle />
            ) 
        }
        return this.props.children
    }
}

export default ErrorBoudary

